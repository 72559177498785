<template>
    <div
        v-if="message"
        class="portal-feedback"
    >
        <div
            :class="`portal-feedback portal-feedback--${messageType}`"
            @click="clearMessage"
        >
            <span class="portal-feedback__content">
                <span class="portal-feedback__icon material-symbols-outlined">{{ icon }}</span>
                <span class="portal-feedback__message" data-test="portal-feedback-message">
                    {{ message }}
                </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: null,
            messageType: null,
        };
    },

    computed: {
        icon() {
            switch (this.messageType) {
                case 'success':
                    return 'check';
                case 'warning':
                    return 'warning';
                case 'danger':
                    return 'report';
                case 'info':
                    return 'info';
                default:
                    return '';
            }
        },
    },

    watch: {
        '$page.props.flash.success': {
            handler: function(message) {
                this.clearMessage();
                this.$nextTick(() => {
                    this.message = message;
                    this.messageType = 'success';
                    if (this.message) {
                        setTimeout(this.clearMessage, 5000);
                    }
                });
            },
        },

        '$page.props.flash.warning': {
            handler: function(message) {
                this.clearMessage();
                this.$nextTick(() => {
                    this.message = message;
                    this.messageType = 'warning';
                    if (this.message) {
                        setTimeout(this.clearMessage, 5000);
                    }
                });
            },
        },

        '$page.props.flash.error': {
            handler: function(message) {
                this.clearMessage();
                this.$nextTick(() => {
                    this.message = message;
                    this.messageType = 'danger';
                    if (this.message) {
                        setTimeout(this.clearMessage, 5000);
                    }
                });
            },
        },

        '$page.props.flash.info': {
            handler: function(message) {
                this.clearMessage();
                this.$nextTick(() => {
                    this.message = message;
                    this.messageType = 'info';
                    if (this.message) {
                        setTimeout(this.clearMessage, 5000);
                    }
                });
            },
        },
    },

    methods: {
        clearMessage() {
            this.message = null;
            this.messageType = null;
        },
    },
};
</script>

<style lang="scss">
@import './resources/assets/scss/shared/extends/multi-load/multi-load',
'portal-feedback';
</style>
