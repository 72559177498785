<template>
    <div
        class="page-menu"
        :class="{'page-menu--mobile-open': mobileMenuOpen}"
        @mouseover="enableHover && openMenu()"
        @mouseleave="enableHover && closeMenu()"
    >
        <div class="page-menu__mobile-close-outside" @click="closeMenu"></div>

        <header class="page-menu__header">
            <img class="page-menu__header-image" src="../../../../../assets/gfx/lio-mark-white.svg">
        </header>

        <button class="page-menu__mobile-close" @click="closeMenu">
            <span class="material-symbols">chevron_left</span>
        </button>

        <div class="menu">
            <menu class="menu__items">
                <li
                    v-for="menuItem in menuItems"
                    class="menu__item"
                    :class="{'menu__item--active': $page.component.startsWith(menuItem.component)}"
                    :data-menu-item="menuItem.component"
                >
                    <div
                        v-if="menuItem.subItems"
                        class="menu__item-link menu__item-link--dropdown"
                        :class="{'open': showSubMenu[menuItem.id]}"
                    >
                        <div
                            class="menu__item-label"
                            @click="toggleSubMenu(menuItem)"
                        >
                            <span class="menu__item-icon material-symbols-outlined">
                                {{ menuItem.icon }}
                                <div
                                    v-if="menuItem.badge"
                                    class="menu__item-badge"
                                >
                                    <span class="menu__item-badge-number">{{ menuItem.badge }}</span>
                                </div>
                            </span>

                            <span class="menu__item-text">{{ menuItem.title }}</span>
                        </div>

                        <div
                            class="menu__sub-items"
                            :class="{'show': showSubMenu[menuItem.id] && enableSubMenu}"
                        >
                            <div
                                v-for="subItem in menuItem.subItems"
                                class="menu__sub-item"
                                :class="[
                                    {'show': showSubMenu[menuItem.id] && enableSubMenu},
                                    {'active': $page.component.startsWith(subItem.component)}
                                ]"
                                :data-menu-item="menuItem.component"
                                data-test="menu-item"
                            >
                                <Link
                                    :href="subItem.route"
                                    class="menu__sub-item-link"
                                    @click="highlightDropdown($event); closeMenuMobile()"
                                >
                                    {{ subItem.title }}
                                </Link>
                            </div>
                        </div>
                    </div>

                    <Link
                        v-else
                        :href="menuItem.route"
                        :method="menuItem.method"
                        :as="menuItem.method ? 'button' : 'a'"
                        class="menu__item-link"
                        data-test="menu-item"
                        @click="highlightDropdown($event); closeMenuMobile()"
                    >
                        <div
                            class="menu__item-label"
                        >
                            <span class="menu__item-icon material-symbols-outlined">
                                {{ menuItem.icon }}
                                <div
                                    v-if="menuItem.badge"
                                    class="menu__item-badge"
                                >
                                    <span class="menu__item-badge-number">{{ menuItem.badge }}</span>
                                </div>
                            </span>
                            <span class="menu__item-text">{{ menuItem.title }}</span>
                        </div>
                    </Link>
                </li>
            </menu>
        </div>
    </div>
</template>

<script>

export default {

    props: {
        mobileMenuOpen: {
            type: Boolean,
        },
    },

    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },

            showSubMenu: {},
            id: null,
            enableHover: false,
            enableSubMenu: false,
        };
    },

    computed: {
        user() {
            return this.$page.props.auth.user;
        },

        menuItems() {
            return this.$page.props.menu;
        },
    },

    methods: {
        desktopMenuHandler() {
            if (window.innerWidth >= 768) {
                this.enableHover = true;
            } else {
                this.enableHover = false;
                this.enableSubMenu = true;
            }
        },

        openMenu() {
            this.enableSubMenu = true;
            this.$emit('open-menu');
        },

        closeMenu() {
            if (window.innerWidth < 768) {
                this.enableSubMenu = true;
                this.$emit('close-menu');
                return;
            }

            this.enableSubMenu = false;
            this.$emit('close-menu');
        },

        closeMenuMobile() {
            if (window.innerWidth < 768) {
                this.enableSubMenu = true;
                this.$emit('close-menu');
            }
        },

        toggleSubMenu(menuItem) {
            this.$set(this.showSubMenu, menuItem.id, !this.showSubMenu[menuItem.id]);
        },

        highlightDropdown(event) {
            const allDropdowns = Array.from(this.$el.querySelectorAll('.menu__item-link--dropdown'));
            const allMenuItems = Array.from(this.$el.querySelectorAll('.menu__item'));

            allDropdowns.forEach(item => {
                const menuItems = item.closest('.menu__item');
                menuItems.classList.remove('menu__item--active');
            });

            allMenuItems.forEach(item => {
                item.classList.remove('menu__item--active');
            });

            const menuItem = event.target.closest('.menu__item');
            menuItem.classList.add('menu__item--active');
        },

        setHighlight() {
            const activeSubItem = document.querySelector('.menu__sub-item.active');

            if (activeSubItem) {
                const menuItem = activeSubItem.closest('.menu__item');
                menuItem.classList.add('menu__item--active');
            }
        },

        setHighlightOnRefresh() {
            const menuItems = Array.from(this.$el.querySelectorAll('.menu__item'));

            menuItems.forEach(menuItem => {
                const attribute = menuItem.getAttribute('data-menu-item');
                if (this.$page.component.startsWith(attribute)) {
                    menuItem.classList.add('menu__item--active');
                }
            });
        },
    },

    mounted() {
        window.addEventListener('resize', this.desktopMenuHandler);
        this.setHighlightOnRefresh();
        this.desktopMenuHandler();
        this.setHighlight();
    },

    destroyed() {
        window.removeEventListener('resize', this.desktopMenuHandler);
    },

};
</script>

<style lang="scss">
@import './resources/assets/scss/shared/extends/multi-load/multi-load',
'page-menu';
</style>
