<template>
    <portal-dialog
        ref="cookieDialog"
        name="cookieDialog"
        title="Your privacy"
        :visible="true"
        :on-close="() => $emit('closed')"
        :before-close="beforeClose"
        size="small"
        header-background-color="purple"
        :closeable="false"
        icon-after="cookie"
        data-test="cookieDialog"
    >
        <section class="cookie-dialog">
            <form
                @submit.prevent="updateCookieConsent"
            >
                <div class="cookie-dialog__form">
                    <span class="cookie-dialog__text">
                        We use performance cookies which help us analyze and optimize your experience on the Partner Portal and improve our services.
                        <br><br>
                        You can withdraw your consent at any time by using the settings under your profile page. <a href="/pdf/Leads-io-PrivacyPolicy-Portal.pdf" download>Privacy Policy</a>.
                    </span>
                </div>


                <div class="cookie-dialog__buttons">
                    <portal-button
                        class="portal-button--secondary"
                        data-test="rejectButton"
                        :disabled="form.processing"
                        @click="() => handleCookieConsent(false)"
                    >
                        Reject
                    </portal-button>
                    <portal-button
                        class="portal-button--primary"
                        :disabled="form.processing"
                        @click="() => handleCookieConsent(true)"
                    >
                        Accept
                    </portal-button>
                </div>
            </form>
        </section>
    </portal-dialog>
</template>

<script>
import PortalButton from '../portal-button/portal-button.vue';
import { useForm } from '@inertiajs/vue2';

export default {
    components: {
        PortalButton,
        PortalDialog: () => import('../portal-dialog/portal-dialog.vue'),
    },

    data() {
        return {
            form: useForm({
                cookie_consent: false,
            }),

            errors: {
                message: null,
            },
        };
    },

    methods: {
        handleCookieConsent(consent) {
            this.form.cookie_consent = consent;

            this.form.post(this.$route('update-cookie-consent'), {
                preserveScroll: true,
                onError: () => {
                    this.$page.props.flash.error = 'Cookie consent could not be saved.';
                },
                onFinish: () => {
                    this.$emit('closed');
                },
            });
        },

        beforeClose(event) {
            event.stopPropagation();
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'cookie-dialog';
</style>import { data } from 'cypress/types/jquery';
