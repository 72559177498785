<template>
    <section class="info-modal" :class="{'info-modal--active' : active }">
        <div class="info-modal__top">
            <div class="info-modal__top-row">
                <div class="info-modal__intro-texts"
                     :class="{'info-modal__intro-texts--active' : active }"
                     :style="{'height' : adjustHeightTitle + 'px'}"
                     ref="title"
                >
                    <span
                        v-for="(titleText, index) in titleTexts"
                        :class="{'info-modal__intro-text--active' : (index + 1) === activeStep }"
                        class="info-modal__intro-text"
                        :data-step="index + 1"
                        v-html="titleText"
                    />
                </div>
                <span class="info-modal__close material-symbols-outlined" @click="close">close</span>
            </div>

            <div class="info-modal__messages">
                <span
                    v-for="(messageText, index) in messageTexts"
                    :class="{'info-modal__message--active' : (index + 1) === activeStep }"
                    class="info-modal__message"
                    :data-step="index + 1"
                    v-html="messageText"
                    ref="text"
                />
            </div>

            <div v-if="totalSteps > 1 " class="info-modal__buttons">
                <span
                    :class="{'info-modal__button--disabled' : activeStep === 1}"
                    class="info-modal__button info-modal__button--prev"
                    @click="activeStep--"
                >
                    Prev
                </span>
                <div class="info-modal__counter">
                    <span class="info-modal__counter-count">{{ activeStep }}</span>
                    <span class="info-modal__counter-divider">of</span>
                    <span class="info-modal__counter-total">{{ totalSteps }}</span>
                </div>
                <span
                    class="info-modal__button info-modal__button--next" @click="activeStep++"
                    :class="{'info-modal__button--disabled' : activeStep === totalSteps}"
                >
                    Next
                </span>
            </div>
        </div>

        <div class="info-modal__bottom">
            <div
                class="info-modal__manager-avatar"
                :class="convertedInitials.length <= 2 ? 'font-size--standard' : 'font-size--smaller'"
            >
                {{ convertedInitials }}
            </div>
            <span class="info-modal__manager-name">{{ campaignManagerName }}</span>
        </div>
    </section>
</template>

<script>

export default {
    data() {
        return {
            titleTexts: [],
            messageTexts: [],
            totalSteps: 0,
            activeStep: 1,
            active: false,
            adjustHeightTitle: '',
            adjustHeightText: '',
        };
    },

    computed: {
        infoModals() {
            return this.$page.props.infoModals
        },

        campaignManagerName() {
            return this.$page.props.contact.campaignManager.name
        },

        convertedInitials() {
            const campaignManagerName = this.campaignManagerName;

            if (campaignManagerName) {
                return campaignManagerName.split(' ').map(x => x[0].toUpperCase()).join('');
            }

            return 'NA';
        }
    },

    mounted() {
        this.$root.$on('open-info-modal', (identifier) => {
            this.showInfoModal(identifier);
        });

        this.$root.$on('open-custom-info-modal', (title, message) => {
            this.showCustomInfoModal(title, message);
        });
    },

    methods: {
        showInfoModal(identifier) {
            this.active = false;
            this.titleTexts = [];
            this.messageTexts = [];
            this.totalSteps = 0;
            this.activeStep = 1;

            if (!this.infoModals || !this.infoModals[identifier]) {
                return;
            }

            for (const infoModal of this.infoModals[identifier]) {
                this.totalSteps++;

                this.titleTexts.push(infoModal.title);
                this.messageTexts.push(infoModal.content);
            }

            this.active = true;
        },

        showCustomInfoModal(title, message) {
            this.titleTexts = [title];
            this.messageTexts = [message];
            this.totalSteps = 1;
            this.activeStep = 1;
            this.active = true;
        },

        close() {
            this.active = false;
        },
    },

    beforeDestroy() {
        this.$root.$off('open-info-modal');
        this.$root.$off('open-custom-info-modal');
    },
};
</script>

<style lang="scss">
@import './resources/assets/scss/shared/extends/multi-load/multi-load',
'info-modal';
</style>
