import { defineStore } from 'pinia';

export const useThemeStore = defineStore('theme', {
    state: () => ({
        darkMode: null,
    }),

    actions: {
        setDarkMode(value) {
            this.darkMode = value;
            localStorage.setItem('darkMode', value);
            this.detectColorScheme();
        },

        detectColorScheme() {
            if (!this.darkMode) {
                this.darkMode = JSON.parse(localStorage.getItem('darkMode'));
            }

            let theme;
            if (this.darkMode) {
                theme = 'dark';
            } else if (this.darkMode === null && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                theme = 'dark';
            } else {
                theme = 'light';
            }

            document.documentElement.setAttribute('data-theme', theme);
        }
    },

    getters: {
        darkModeLabel: state => {
            if (state.darkMode) {
                return'On';
            } else if (state.darkMode === null && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                return 'On';
            } else {
                return 'Off';
            }
        },
    }
});
