<template>
    <div>
        <div id="application" data-style>
            <div
                id="page"
                ref="rootPage"
                :class="{
                    'menu--open': menuOpen === true,
                }"
            >
                <PageMenu
                    :mobile-menu-open="menuOpenMobile"
                    @open-menu="openMenu"
                    @close-menu="closeMenu"
                />

                <div id="page__content">
                    <portal-feedback />

                    <slot></slot>

                    <PageFooter />

                    <info-modal />

                    <cookie-dialog
                        v-if="cookieConsent === null && !$page.props.auth.user.impersonating"
                        class="dialog--cookie"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from './components/page-header/page-header.vue';
import PageMenu from './components/page-menu/page-menu.vue';
import PortalFeedback from './components/portal-feedback/portal-feedback.vue';
import PageFooter from './components/page-footer/page-footer.vue';
import InfoModal from './components/info-modal/info-modal.vue';
import CookieDialog from './components/cookie-dialog/cookie-dialog.vue';
import { mapActions } from 'pinia';
import { useThemeStore } from '../store/theme.js';

export default {
    components: {
        CookieDialog,
        PageHeader,
        PageMenu,
        PortalFeedback,
        PageFooter,
        InfoModal,
    },

    props: {
        datadog: {
            type: Array,
            required: true,
        },

        cookieConsent: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            headerOptions: {},
            showHeader: false,
            currentToken: null,
            debounce: null,
            menuOpen: false,
            menuOpenMobile: false,
            cookieConsent: false,
        };
    },

    watch: {
        cookieConsent() {
            this.cookieConsent = this.$page.props.cookieConsent;
        },
    },

    methods: {
        ...mapActions(useThemeStore, ['detectColorScheme']),

        setDatadog() {
            if (window.Datadog) {
                window.Datadog = {
                    enabled: this.datadog.enabled,
                    application_id: this.datadog.application_id,
                    client_token: this.datadog.client_token,
                    name: this.datadog.app_name,
                    env: this.datadog.app_env,
                    user: this.datadog.user,
                    client_id: this.datadog.client_id,
                    client_name: this.datadog.client_name,
                };
            }
        },

        openMenu() {
            this.menuOpen = true;
            this.checkMenuOpenMobile();
        },

        closeMenu() {
            this.menuOpen = false;
            this.checkMenuOpenMobile();
        },

        checkMenuOpenMobile() {
            setTimeout(() => {
                if (window.innerWidth < 768 && this.menuOpen === true) {
                    this.mobileMenuOpen = true;
                }
            }, 200);

            this.mobileMenuOpen = false;
        },
    },

    created() {
        this.setDatadog();
    },

    mounted() {
        this.detectColorScheme();
        this.$root.$on('open-menu', this.openMenu);
    },
};
</script>

<style lang="scss">
@import "resources/client/scss/extends/client/main";
</style>
