import { datadogRum } from '@datadog/browser-rum';

export default {
    install: () => {
        if (!window.Datadog?.application_id || !window.Datadog?.client_token) {
            return;
        }

        if (window.Datadog.enabled) {
            datadogRum.init({
                applicationId: window.Datadog.application_id,
                clientToken: window.Datadog.client_token,
                site: 'datadoghq.eu',
                service: window.Datadog.app_name,
                env: window.Datadog.app_env,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackResources: true,
                trackLongTasks: true,
                trackUserInteractions: true,
            });

            if (window.Datadog.user) {
                datadogRum.setUser({
                    id: window.Datadog.user.id,
                    name: window.Datadog.user.name,
                    email: window.Datadog.user.email,
                    client_id: window.Datadog.client_id,
                    client_name: window.Datadog.client_name,
                });
            }

            datadogRum.startSessionReplayRecording();
        }
    },
};
