<template>
    <footer
        class="page-footer"
        :class="{'page-footer--clean': cleanLayout}"
    >
        <div class="page-footer__rights">
            <span class="page-footer__rights-text">All rights reserved:</span>
            <a class="page-footer__rights-link" href="/pdf/Leads-io-Imprint-Portal.pdf" download>Imprint</a>
            <a class="page-footer__rights-link" href="/pdf/Leads-io-PrivacyPolicy-Portal.pdf" download>Privacy
                Policy</a>
            <a class="page-footer__rights-link" href="/pdf/Leads-io-TermsOfService-Portal.pdf" download>Terms of
                Service</a>
        </div>

        <span class="page-footer__copyright">
            <span class="page-footer__copyright-text">Leads.io - Portal {{ (new Date()).getFullYear() }}</span>
            <img class="page-footer__copyright-image" src="../../../../../assets/gfx/lio-mark-white.svg">
        </span>
    </footer>
</template>

<script>
export default {
    props: {
        cleanLayout: {
            type: Boolean,
            default: false,
            required: false,
        },
    }
};
</script>

<style lang="scss">
@import 'page-footer';
</style>
