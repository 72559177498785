import './init';
import Vue from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue2';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import Layout from './layout/default.vue';
import datadog from './datadog';
import { InfoModalIdentifiers } from '../../assets/js/global/InfoModalIdentifiers';
import vue_chartist from 'vue-chartist';
import { createPinia } from 'pinia';

// Oscar components
import Oscar from 'oscar/dist/oscar.es';

Vue.use(Oscar);
Vue.use(createPinia());
Vue.use(vue_chartist);

createInertiaApp({
    progress: {},
    resolve: name => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob('./pages/**/*.vue'))
        .then(page => {
            page.default.layout = page.default.layout || Layout;
            return page;
        }),
    setup({ el, App, props, plugin }) {
        Vue.use(plugin);
        import('./plugins/chartist/chartist-plugin-tooltip');
        Vue.component('Link', Link);

        if (window.route) {
            Vue.prototype.$route = window.route;
        }

        Vue.prototype.InfoModalIdentifiers = InfoModalIdentifiers;

        new Vue({
            render: h => h(App, props),
        }).$mount(el);
        Vue.use(datadog);
    },
});

// "Legacy", to support socialblue components
window.EventBus = window.EventBus ?? {
    $on: function() {
    },
    $off: function() {
    },
    $emit: function() {
    },
};
